.dochub {
  &-component {
    &-user-menu,
    &-create-menu {
      position: relative;

      &[open] > &__summary::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 80;
        display: block;
        cursor: default;
        content: " ";
        background: transparent;
      }

      &__summary {
        display: flex;
        align-items: center;
        cursor: pointer;

        &::marker {
          display: none;
        }

        &__avatar {
          min-width: 20px;
          min-height: 20px;
          border-radius: 4px;
          margin-right: 4px;
        }
      }
      &__list {
        position: absolute;
        left: auto;
        right: 0;
        width: 180px;
        margin-top: 8px;
        padding: 0.5em 0;
        border: 1px solid var(--md-sys-color-outline);
        border-radius: 8px;
        z-index: 100;
        color: var(--md-sys-color-on-primary-container);
        &::before {
          position: absolute;
          left: auto;
          right: 9px;
          top: -16px;
          border: 8px solid #0000;
          border-bottom: 8px solid var(--md-sys-color-outline);
          content: "";
        }
        &__item {
          padding: 0.5em 1em;
          cursor: pointer;
          &:hover {
            color: var(--md-sys-color-on-primary);
            background: var(--md-sys-color-primary);
          }
          &--link {
            padding: 0;
            & > a {
              display: block;
              padding: 0.5em 1em;
              color: inherit;
              background: none;
              &:hover {
                color: inherit;
              }
            }
          }
        }
        &__divider {
          border-top: 1px solid #999;
          margin: 0.2rem 0;
        }
      }

      .dropdown-caret {
        color: #fff;
        border-bottom-color: #0000;
        border-left-color: #0000;
        border-right-color: #0000;
        border-style: solid;
        border-width: 4px 4px 0;
        height: 0;
        width: 0;
        content: "";
      }
    }
  }
}
