:root {
  --md-sys-elevation-1: 0px 3px 1px -2px var(--md-sys-color-outline),
    0px 2px 2px 0px var(--md-sys-color-outline),
    0px 1px 5px 0px var(--md-sys-color-outline);
}

body {
  font-size: var(--md-sys-typescale-body-medium-font-size);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: var(--md-sys-color-on-background);
  background: var(--md-sys-color-background);
}

a {
  color: var(--md-sys-color-primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:active {
  color: var(--md-sys-color-primary);
  text-decoration: underline;
}

.select {
  padding: 4px 0.5rem;
  border: 1px solid var(--md-sys-color-outline);
  background: var(--md-sys-color-surface-light);
  color: var(--md-sys-color-on-surface-light);
  border-radius: 6px;
  font-size: var(--md-sys-typescale-label-large-font-size);
  font-weight: var(--md-sys-typescale-label-large-font-weight);
  height: calc(var(--md-sys-typescale-label-large-line-height) + 10px);
  &:focus-visible {
    outline-color: var(--md-sys-color-primary);
  }
}

.input {
  padding: 4px 0.5rem;
  border: 1px solid var(--md-sys-color-outline);
  background: var(--md-sys-color-surface-light);
  color: var(--md-sys-color-on-surface-light);
  border-radius: 6px;
  font-size: var(--md-sys-typescale-label-large-font-size);
  font-weight: var(--md-sys-typescale-label-large-font-weight);
  line-height: var(--md-sys-typescale-label-large-line-height);
  &:focus-visible {
    outline-color: var(--md-sys-color-primary);
  }
}

.btn {
  position: relative;
  display: inline-block;
  padding: 5px 16px;
  color: var(--md-sys-color-on-background);
  background: var(--md-sys-color-background);
  font-size: var(--md-sys-typescale-label-large-font-size);
  font-weight: var(--md-sys-typescale-label-large-font-weight);
  line-height: var(--md-sys-typescale-label-large-line-height);
  letter-spacing: var(--md-sys-typescale-label-large-letter-tracking);
  text-decoration: none;
  border: 1px solid var(--md-sys-color-outline);
  border-radius: 6px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &:hover,
  &:active {
    box-shadow: var(--md-sys-elevation-1);
    text-decoration: none;
  }

  &:active {
    box-shadow: none;
  }
}

.btn-small {
  padding: 2px 8px;
}

.btn-primary {
  color: var(--md-sys-color-on-secondary);
  background-color: var(--md-sys-color-secondary);
  border: none;

  &:hover,
  &:active {
    color: var(--md-sys-color-on-secondary);
  }
}

.btn-danger {
  color: var(--md-sys-color-on-error);
  background-color: var(--md-sys-color-error);
  border: none;

  &:hover,
  &:active {
    color: var(--md-sys-color-on-error);
  }
}

.nav {
  display: flex;
  &__item {
    position: relative;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    &:hover {
      border-radius: 0.25rem;
      background: var(--md-sys-color-surface);
    }

    &--active {
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: var(--md-sys-color-on-surface);
        content: "";
      }
    }
  }
}

.dochub-icon {
  fill: currentColor;
}
