.dochub {
  &-component {
    &-loading {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($color: #fff, $alpha: 0.5);

      &--show {
        display: block;
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3em;
        height: 3em;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
