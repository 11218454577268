$header-color: #fff;

.dochub {
  &-component {
    &-header {
      height: 48px;
      display: flex;
      align-items: center;
      background: #24292f;
      padding: 0 1rem;
      color: $header-color;

      &__name,
      &__name:hover,
      &__name:active {
        font-size: 1.8em;
        color: $header-color;
        text-decoration: none;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
